export enum PRODUCT_TYPE {
  ENT = 'ENT',
  PLA = 'PLA',
  DES = 'DES',
  BOI = 'BOI',
  FOR = 'FOR',
  // LAI = 'LAI',
  FRO = 'FRO',
  ACC = 'ACC',
  // AUT = 'AUT',
  WRA = 'WRA',
  FOC = 'FOC',
  SAL = 'SAL',
  BAG = 'BAG',
  BRE = 'BRE',
}

export enum SERVICE_TYPE {
  PD = 'PD',
  CO = 'CO',
  DJ = 'DJ',
  GO = 'GO',
  SO = 'SO',
}
