import {Nutriscore, Picto} from '../interfaces';
import {PRODUCT_TYPE} from './enums';

export const ALLERGENES: Picto[] = [
  {
    name: 'Arachides',
    id: 'ARA',
    fileName: 'arachides.svg',
  },
  {
    name: 'Celeri',
    id: 'CEL',
    fileName: 'celeri.svg',
  },
  {
    name: 'Crustacés',
    id: 'CRU',
    fileName: 'crustaces.svg',
  },
  {
    name: 'Fruits à coque',
    id: 'FCQ',
    fileName: 'fruits-a-coque.svg',
  },
  {
    name: 'Gluten',
    id: 'GLU',
    fileName: 'gluten.svg',
  },
  {
    name: 'Lait',
    id: 'LAI',
    fileName: 'lait.svg',
  },
  {
    name: 'Lupin',
    id: 'LUP',
    fileName: 'lupin.svg',
  },
  {
    name: 'Mollusques',
    id: 'MOL',
    fileName: 'mollusques.svg',
  },
  {
    name: 'Moutarde',
    id: 'MOU',
    fileName: 'moutarde.svg',
  },
  {
    name: 'Oeuf',
    id: 'OEU',
    fileName: 'oeuf.svg',
  },
  {
    name: 'Poisson',
    id: 'POI',
    fileName: 'poisson.svg',
  },
  {
    name: 'Sésame',
    id: 'SES',
    fileName: 'sesame.svg',
  },
  {
    name: 'Soja',
    id: 'SOJ',
    fileName: 'soja.svg',
  },
  {
    name: 'Sulfite',
    id: 'SUL',
    fileName: 'sulfite.svg',
  },
];

export const ORIGINES: Picto[] = [
  {
    name: 'Agriculture biologique',
    id: 'ab',
    fileName: 'ab.webp',
  },
  {
    name: "Appellation d'origine contrôlée",
    id: 'aoc',
    fileName: 'aoc.webp',
  },
  {
    name: "Appellation d'origine protégée",
    id: 'aop',
    fileName: 'aop.webp',
  },
  {
    name: 'Viande chèvre française',
    id: 'chf',
    fileName: 'chf.webp',
  },
  {
    name: 'Viande chevreau française',
    id: 'cvf',
    fileName: 'cvf.webp',
  },
  {
    name: 'Foie gras de France',
    id: 'fgf',
    fileName: 'fgf.webp',
  },
  {
    name: 'Fait maison',
    id: 'fm',
    fileName: 'fm.webp',
  },
  {
    name: 'Indication géographique protégée',
    id: 'igp',
    fileName: 'igp.webp',
  },
  {
    name: 'Lapin de France',
    id: 'laf',
    fileName: 'laf.webp',
  },
  {
    name: 'Local',
    id: 'loc',
    fileName: 'loc.webp',
  },
  {
    name: 'Label Rouge',
    id: 'lr',
    fileName: 'lr.webp',
  },
  {
    name: 'MSC',
    id: 'msc',
    fileName: 'msc.webp',
  },
  {
    name: 'Oeufs de France',
    id: 'oef',
    fileName: 'oef.webp',
  },
  {
    name: 'Pêche durable',
    id: 'pdu',
    fileName: 'pdu.webp',
  },
  {
    name: 'Le porc français',
    id: 'pof',
    fileName: 'pof.webp',
  },
  {
    name: 'Région ultrapériphérique UE',
    id: 'rup',
    fileName: 'rup.webp',
  },
  {
    name: "Viande d'agneau français",
    id: 'vaf',
    fileName: 'vaf.webp',
  },
  {
    name: 'Viande bovine française',
    id: 'vbf',
    fileName: 'vbf.webp',
  },
  {
    name: 'Viande chevaline française',
    id: 'vcf',
    fileName: 'vcf.webp',
  },
  {
    name: 'Volaille française',
    id: 'vlf',
    fileName: 'vlf.webp',
  },
  {
    name: 'Viande ovine française',
    id: 'vof',
    fileName: 'vof.webp',
  },
  {
    name: 'Viande de veau française',
    id: 'vvf',
    fileName: 'vvf.webp',
  },
];

export const PRODUCT_TYPES: Picto[] = [
  {
    name: 'Entrée',
    id: PRODUCT_TYPE.ENT,
    fileName: 'entrees.svg',
  },
  {
    name: 'Plat',
    id: PRODUCT_TYPE.PLA,
    fileName: 'plats.svg',
  },
  {
    name: 'Accompagnement',
    id: PRODUCT_TYPE.ACC,
    fileName: 'accompagnements.svg',
  },
  {
    name: 'Dessert',
    id: PRODUCT_TYPE.DES,
    fileName: 'desserts.svg',
  },
  {
    name: 'Fromage',
    id: PRODUCT_TYPE.FRO,
    fileName: 'fromages.svg',
  },
  // {
  //   name: 'Laitage',
  //   id: PRODUCT_TYPE.LAI,
  //   fileName: 'laitages.svg',
  // },
  {
    name: 'Boisson',
    id: PRODUCT_TYPE.BOI,
    fileName: 'boissons.svg',
  },
  // {
  //   name: 'Autre',
  //   id: PRODUCT_TYPE.AUT,
  //   fileName: 'formules.svg',
  // },
  // {
  //   name: 'Formule',
  //   id: PRODUCT_TYPE.FOR,
  //   fileName: 'formules.svg',
  // },

  {
    name: 'Wrap',
    id: PRODUCT_TYPE.WRA,
    fileName: 'wrap.svg',
  },
  {
    name: 'Focaccia',
    id: PRODUCT_TYPE.FOC,
    fileName: 'focaccia.svg',
  },
  {
    name: 'Bagel',
    id: PRODUCT_TYPE.BAG,
    fileName: 'bagel.svg',
  },
  {
    name: 'Baguette',
    id: PRODUCT_TYPE.BRE,
    fileName: 'baguette.svg',
  },
  {
    name: 'Salade',
    id: PRODUCT_TYPE.SAL,
    fileName: 'salade.svg',
  },
];

export const NUTRISCORE: Nutriscore[] = [
  {
    id: 1,
    fileName: '1.svg',
    fileNameVertical: 'v_1.svg',
  },
  {
    id: 2,
    fileName: '2.svg',
    fileNameVertical: 'v_2.svg',
  },
  {
    id: 3,
    fileName: '3.svg',
    fileNameVertical: 'v_3.svg',
  },
  {
    id: 4,
    fileName: '4.svg',
    fileNameVertical: 'v_4.svg',
  },
  {
    id: 5,
    fileName: '5.svg',
    fileNameVertical: 'v_5.svg',
  },
];
